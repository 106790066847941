define("discourse/plugins/nta-post-metadata/discourse/initializers/renderer", ["exports", "virtual-dom", "discourse/widgets/widget", "discourse-common/lib/icon-library", "discourse/lib/transform-post"], function (_exports, _virtualDom, _widget, _iconLibrary, _transformPost) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "renderer",
    initialize() {
      (0, _transformPost.includeAttributes)("presence_metadata");
      (0, _widget.reopenWidget)("post-links", {
        html(attrs, state) {
          let retval = this._super(attrs, state);
          const nodes = retval?.children ?? [];
          const presence = this.attrs.presence_metadata;
          if (presence) {
            // homespace
            const zone = presence["sensor.dewford2_geocoded_location_zones"]?.[0];
            if (zone) {
              nodes.push(funnyHtml(zone, "home"));
            }

            // device
            const device = presence["device"];
            const deviceIcon = presence["device_icon"];
            if (deviceIcon && device) {
              nodes.push(funnyHtml(device, deviceIcon));
            }

            // steam game
            const steamGame = presence["sensor.steam_76561198000591811_game"];
            if (steamGame) {
              nodes.push(funnyHtml(steamGame, 'fab-steam', `https://store.steampowered.com/app/${presence["sensor.steam_76561198000591811_game_id"]}`));
            }

            // discord game
            const discordGame = presence["sensor.discord_user_66181239664545792_game"];
            if (discordGame && discordGame !== steamGame && !steamGame?.startsWith(discordGame)) {
              nodes.push(funnyHtml(discordGame, 'fab-discord'));
            }

            // youtube np
            const ytm = presence["ytm_np"];
            if (ytm) {
              // TODO: hover with album/thumbnail?
              nodes.push(funnyHtml(ytm, 'fa-music', `https://music.youtube.com/watch?v=${presence["ytm_video"]}`));
            }
          }
          if (nodes.length > 0) {
            retval = (0, _virtualDom.h)("ul.post-links", nodes);
          }
          return retval;
          function funnyHtml(label, icon, link) {
            const node = [(0, _iconLibrary.iconNode)(icon), label];
            return (0, _virtualDom.h)("li.inline-link", link ? (0, _virtualDom.h)('a', {
              attributes: {
                href: link
              }
            }, node) : node);
          }
        }
      });
    }
  };
});